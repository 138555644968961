import {createGlobalStyle} from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fontawesome-all.css';
import './styles.css';

export const GlobalStyle = createGlobalStyle`
	:root {
		--main-bg-color: #fff;
	}

	.header-svg,
	.footer-svg {
		fill: #5f4def;
	}
`;

